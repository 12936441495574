import * as React from 'react';
import { linkAccountsApi } from '../api/yandex_api';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import BtnFilled from '../components/BtnFilled';
import Input from '../components/Input';
import InputPasswd from '../components/InputPasswd';
import Modal from '../components/Modal';
import AlertAccent from '../components/AlertAccent';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingSpinner from '../components/LoadingSpinner';
import AlertError from '../components/AlertError';

const Login = ({ setUser, setAuthorized }) => {
  let navigate = useNavigate();
  const [refreshing, setRefreshing] = React.useState(false);
  const [linkAccountsErr, setLinkAccountsErr] = React.useState([]);

  const location = useLocation();
  const [searchParams] = useSearchParams();

  var testLogIn =
    process.env.REACT_APP_MODE === 'dev'
      ? {
          username: 'rise33@yandex.ru',
          password: '$outh3*3Cross',
        }
      : {};

  const [params, setParams] = React.useState(testLogIn);
  const [error, setError] = React.useState({});

  //?scope=home%3Alights&state=https%3A%2F%2Fsocial.yandex.ru%2Fbroker2%2Fauthz_in_web%2F0348ab88c05a4d8d929172d8e045df52%2Fcallback&redirect_uri=https%3A%2F%2Fsocial.yandex.net%2Fbroker%2Fredirect&response_type=code&client_id=4760187d41bc4b7799476b42b5103213
  React.useEffect(() => {
    setParams({
      ...params,
      scope: searchParams.get('scope'),
      state: searchParams.get('state'),
      redirect_uri: searchParams.get('redirect_uri'),
      response_type: searchParams.get('response_type'),
      client_id: searchParams.get('client_id'),
    });
  }, []);

  React.useEffect(() => {
    console.log(params);
  }, [params]);

  const linkAccounts = async () => {
    setLinkAccountsErr([]);
    setRefreshing(true);
    await linkAccountsApi(
      params,
      async function (data) {
        //console.log(data.url);
        //window.location.replace(data.url);
        setRefreshing(false);
      },
      function (err) {
        setLinkAccountsErr((p) => [...p, err]);
        toast.error(err.error, { hideProgressBar: false });
        setRefreshing(false);
      }
    );
  };

  return (
    <>
      <>
        <div class="flex w-full min-h-screen p-4 items-start justify-center bg-gradient-to-r from-[#ededed] to-[#dcdcdc]">
          <div class="w-full max-w-lg">
            <form class="bg-white shadow-2xl rounded-3xl border-b border-x border-gray-300 px-8 pt-6 pb-8 mb-4">
              <div class="mb-2">
                <p class="text-gray-800 col-span-5 mr-3 text-center text-xl font-bold">Выполните вход в Yandex через аккаунт Mira</p>
              </div>
              <div class="mb-2 justify-center items-center flex">
                <img src="https://www.mira.cloud/api/v1/dev/images/1713816313537.jpg" class="h-20 max-w-full rounded-full" alt="" />
              </div>
              <div class="mb-4">
                <Input
                  label="Логин"
                  value={params.username}
                  inputCb={(e) => setParams({ ...params, username: e.target.value })}
                  id="username"
                  type="text"
                  autocomplete="username"
                  placeholder={'someone@example.com'}></Input>
              </div>
              <div class="mb-4">
                <InputPasswd
                  label="Пароль"
                  value={params.password}
                  inputCb={(e) => setParams({ ...params, password: e.target.value })}
                  id="password"
                  autocomplete="current-password"></InputPasswd>
              </div>
              <div class="mb-4">
                <AlertError error={linkAccountsErr}></AlertError>
              </div>
              <div class="mb-4">
                <BtnFilled
                  title={
                    refreshing ? (
                      <div
                        className="inline-block h-6 w-6 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"></div>
                    ) : (
                      'Войти'
                    )
                  }
                  btnCb={linkAccounts}></BtnFilled>
              </div>
            </form>
          </div>
          <div>
            <ToastContainer />
          </div>
        </div>
      </>
    </>
  );
};

export default Login;
