//this is counter widget
import React from 'react';
import DeviceNames from '../deviceList/DeviceNames';
import DeviceIcons from '../deviceList/DeviceIcons';
import DeviceValues from '../deviceList/DeviceValues';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Modal from '../components/Modal';
import Chartjs from '../components/Chartjs';
import ToggleSwitch from '../components/ToggleSwitch';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import DropdownSimple from '../components/DropdownSimple';
import { getDevicesEventsApi, getDevicesConsApi } from '../api/api';
import LoadingSpinner from '../components/LoadingSpinner';
import LastEventInfo from './LastEventInfo';

const config = {
  months: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
};

const TabIndices = {
  ALL_TIME: 0,
  MONTHLY: 1,
  YEARLY: 2,
};

const Device = ({ key, device }) => {
  const [devicesEvents, setDevicesEvents] = React.useState([]);
  const [month, setMonth] = React.useState(new Date().getMonth());
  const [lastMonthsArray, setLastMonthsArray] = React.useState({ months: [] });
  const [lastYearsArray, setLastYearsArray] = React.useState([]);
  const [year, setYear] = React.useState(new Date().getFullYear());
  const [cons, setCons] = React.useState(device?.last_event?.value || 0);
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [hasFetched, setHasFetched] = React.useState(false);
  const [showModal, setShowModal] = React.useState(false);

  const prepareMonthsArray2 = async (data) => {
    const currentMonth = new Date().getMonth(); // Получаем текущий месяц (0-11)
    const currentYear = new Date().getFullYear(); // Получаем текущий год
    let monthsWithData = data.reduce((acc, entry) => {
      const entryMonth = new Date(entry.at).getMonth();
      const entryYear = new Date(entry.at).getFullYear();
      const monthYearKey = `${entryYear}-${entryMonth}`;
      if (!acc[monthYearKey]) {
        acc[monthYearKey] = { val: entryMonth, opt: config.months[entryMonth] + ' ' + entryYear, year: entryYear };
      }
      return acc;
    }, {});

    let months = [];
    for (let i = 11; i >= 0; i--) {
      const month = (currentMonth - i + 12) % 12;
      const year = i === 0 ? currentYear : currentYear - Math.floor((i + currentMonth) / 12);
      const monthYearKey = `${year}-${month}`;
      if (monthsWithData[monthYearKey]) {
        months.push(monthsWithData[monthYearKey]);
      }
    }
    return { months: months.reverse() }; // Используем .reverse() для изменения порядка на обратный
  };

  const prepareYearsArray = async () => {
    const currentYear = new Date().getFullYear(); // Получаем текущий год
    let years = [];
    for (let i = 4; i >= 0; i--) {
      const year = currentYear - i;
      years.push({ val: year, opt: year.toString() });
    }
    return { years: years.reverse() }; // Используем .reverse() для изменения порядка на обратный
  };

  // Функция для циклического увеличения activeIndex, исключая клики на DropdownSimple
  const handleContainerClick = async (event) => {
    if (!event.target.closest('#dropdown-simple') && !event.target.closest('#icon-chart') && !event.target.closest('#modal-chart')) {
      setActiveIndex((prevIndex) => (prevIndex === Object.keys(TabIndices).length - 1 ? 0 : prevIndex + 1));
    }
  };

  React.useEffect(() => {
    const get = async () => {
      setLastYearsArray(await prepareYearsArray());
    };
    get();
  }, []);

  React.useEffect(() => {
    const fetchData = async () => {
      if (hasFetched) {
        await calculateConsumption(devicesEvents);
      } else if (activeIndex === TabIndices.MONTHLY || activeIndex === TabIndices.YEARLY) {
        try {
          const events = await getDevicesEvents();
          setLastMonthsArray(await prepareMonthsArray2(events));
          await calculateConsumption(events);
          setDevicesEvents(events);
          setHasFetched(true);
        } catch (error) {
          console.error('Ошибка при получении или расчете данных:', error);
        }
      }
    };

    fetchData();
  }, [activeIndex, month, year]);

  const calculateConsumption = async (devicesEvents) => {
    if (activeIndex === TabIndices.ALL_TIME) {
      setCons(device?.last_event?.value || 0);
    }
    if (activeIndex === TabIndices.MONTHLY || activeIndex === TabIndices.YEARLY) {
      if (activeIndex === TabIndices.MONTHLY) {
        setCons(calculateMonthlyConsumption(devicesEvents, month));
      }
      if (activeIndex === TabIndices.YEARLY) {
        setCons(calculateAnnualConsumption(devicesEvents, year));
      }
    }
  };

  const getDevicesEvents = async () => {
    let dataResult = undefined;
    await getDevicesEventsApi(
      function (data) {
        dataResult = data;
      },
      function (err) {
        // Обработка ошибки
      },
      device.id
    );
    return dataResult;
  };

  const calculateMonthlyConsumption = (data, month) => {
    if (!data.length) return 0;
    const monthlyData = data.filter((entry) => new Date(entry.at).getMonth() === month);
    monthlyData.sort((a, b) => new Date(a.at) - new Date(b.at));
    const startValue = monthlyData[0]?.value || 0;
    const endValue = monthlyData[monthlyData.length - 1]?.value || 0;
    return (endValue - startValue).toFixed(2);
  };

  const calculateAnnualConsumption = (data, year) => {
    if (!data.length) return 0;
    const annualData = data.filter((entry) => new Date(entry.at).getFullYear() === year);
    annualData.sort((a, b) => new Date(a.at) - new Date(b.at));
    const startValue = annualData[0]?.value || 0;
    const endValue = annualData[annualData.length - 1]?.value || 0;
    return (endValue - startValue).toFixed(2);
  };

  return (
    <div className="">
      <div
        onClick={handleContainerClick}
        class="bg-white first:rounded-tl-3xl cursor-pointer rounded-3xl last:rounded-br-3xl p-5 border-l select-none flex flex-col justify-between h-full tap-highlight-transparent">
        <div class="grid grid-cols-6 items-center">
          <DeviceIcons device={device}></DeviceIcons>

          <div class="col-span-5 ml-4">
            <p class="truncate">{device?.type?.name}</p>
            <p class="truncate text-sm text-gray-400">{device?.serial_number}</p>
          </div>
        </div>

        <div className="mt-2 flex-1">
          <p className="truncate text-xs text-gray-400">
            <LastEventInfo device={device}></LastEventInfo>
          </p>
        </div>

        {!isNaN(new Date(device?.last_event?.at)) && (
          <>
            <div class="grid grid-cols-2">
              <div>
                {activeIndex === TabIndices.ALL_TIME && <p className="h-6 mt-2">Расход</p>}

                {activeIndex === TabIndices.MONTHLY && (
                  <DropdownSimple
                    options={lastMonthsArray.months}
                    value={month}
                    onChange={(e) => setMonth(parseInt(e))}
                    id="dropdown-simple"
                    valkey="val"
                    optkey="opt"></DropdownSimple>
                )}

                {activeIndex === TabIndices.YEARLY && (
                  <DropdownSimple
                    options={lastYearsArray.years}
                    value={year}
                    onChange={(e) => setYear(parseInt(e))}
                    id="dropdown-simple"
                    valkey="val"
                    optkey="opt"></DropdownSimple>
                )}

                <p className="text-gray-800 col-span-4 mr-3 justify-self-end text-xl sm:text-xl md:text-xl lg:text-xl xl:text-2xl 2xl:text-2xl">
                  {cons !== undefined ? (
                    cons + ' m³'
                  ) : (
                    <div
                      className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"></div>
                  )}
                </p>
              </div>
              <div class="flex items-center justify-end">
                <div
                  onClick={() => setShowModal(true)}
                  className="flex items-center justify-center bg-gray-100 hover:bg-gray-200 rounded-full p-2"
                  style={{ width: 'fit-content' }}
                  id="icon-chart">
                  <QueryStatsIcon className="text-orange-500 w-6 sm:w-6 xl:w-8 2xl:w-8 h-6 sm:h-6 xl:h-7 2xl:h-7"></QueryStatsIcon>
                </div>
              </div>
            </div>
            <div className="toggle-switch-container pointer-events-none">
              <ToggleSwitch id="toggle-switch" activeIndex={activeIndex} setActiveIndex={setActiveIndex}></ToggleSwitch>
            </div>
          </>
        )}

        <div id="modal-chart">
          <Modal showModal={showModal} setShowModal={setShowModal} title="История данных устройства">
            <Chartjs device={device}></Chartjs>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default Device;
